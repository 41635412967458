import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  LinearProgress,
  Chip,
  Paper,
  Button,
} from '@mui/material';

const TastingResults = ({ tastingData, onEdit }) => {
  if (!tastingData) {
    return null;
  }

  const renderRating = (label, value, descriptions) => (
    <Grid item xs={12}>
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          {label}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="caption">{descriptions[0]}</Typography>
            <Typography variant="caption">{descriptions[1]}</Typography>
          </Box>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={(value / 5) * 100}
              sx={{ height: 15, borderRadius: 5 }}
            />
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: -25, // Adjust as needed to position above the bar
                fontWeight: 'bold',
              }}
            >
              {value} / 5
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Grid>
        <Typography variant="h5" gutterBottom>
          Your Tasting Results
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Check out your tasting notes and ratings for this wine!
        </Typography>
        <Grid container spacing={2}>
        {renderRating('Acidity', tastingData.acidity, ['Low', 'High'])}
          {renderRating('Sweetness', tastingData.sweetness, ['Low', 'High'])}
          {renderRating('Tannins', tastingData.tannins, ['Low', 'High'])}
          {renderRating('Body', tastingData.body, ['Light', 'Full'])}
          {renderRating('Flavor Intensity', tastingData.flavorIntensity, ['Subtle', 'Intense'])}
          {renderRating('Aroma Intensity', tastingData.aromaIntensity, ['Weak', 'Strong'])}

          {/* Selected Flavors */}
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                The flavors you experienced
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  padding: 1,
                  borderRadius: 2,
                }}
              >
                {tastingData.selectedFlavors.length > 0 ? (
                  tastingData.selectedFlavors.map((flavor) => (
                    <Chip key={flavor} label={flavor} color="primary" />
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No flavors selected.
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>

          {/* Tasting Notes */}
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Tasting Notes
              </Typography>
              <Paper
                variant="outlined"
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                }}
              >
                <Typography variant="body2">
                  {tastingData.tastingNotes || 'No tasting notes provided.'}
                </Typography>
              </Paper>
            </Box>
          </Grid>

          {/* Edit Button */}
          <Grid item xs={12}>
            <Box mt={2} textAlign="right">
              <Button variant="contained" color="primary" onClick={onEdit}>
                Edit Tasting
              </Button>
            </Box>
          </Grid>
        </Grid>
</Grid>
  );
};

export default TastingResults;
