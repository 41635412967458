import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Slider, TextField, Box, Snackbar, Alert, Chip } from '@mui/material';

const WineTastingModal = ({ isOpen, onClose, wine, wineId, token }) => {
  const [acidity, setAcidity] = useState(3);
  const [sweetness, setSweetness] = useState(3);
  const [tannins, setTannins] = useState(3);
  const [body, setBody] = useState(3);
  const [flavorIntensity, setFlavorIntensity] = useState(3);
  const [aromaIntensity, setAromaIntensity] = useState(3);
  const [overallRating, setOverallRating] = useState(3);
  const [tastingNotes, setTastingNotes] = useState('');
  const [selectedFlavors, setSelectedFlavors] = useState([]);  // State for selected chips
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [modalClosed, setModalClosed] = useState(false);

  if (!wine) {
    return null;
  }

  const marks = Array.from({ length: 5 }, (_, i) => ({ value: i + 1, label: `${i + 1}` }));

  const flavorFruitOptions = [
    // Fruit Category
    'Red Fruit', 'Black Fruit', 'Citrus', 'Tropical Fruit', 'Stone Fruit', 'Dried Fruit',
  ];

  const flavorFloralHerbalOptions = [
    // Floral & Herbal Category
    'Floral', 'Herbal', 'Grassy', 'Spicy','Lavender','Mint','Sage'
  ];

  const flavorEarthyMineralOptions = [
    // Earthy & Minerality Category
    'Earthy', 'Mineral', 'Smoky','Wet Stone', 'Salinity (Salt)'
  ];

  const flavorOakWoodlOptions = [
    // Oak & Wood Influence
    'Oaky', 'Buttery', 'Tobacco','Coconut (aging Oak)','Caramel'
  ];

  const flavorOtherOptions = [
    // Other Distinguishing Flavors
    'Nuttiness', 'Butterscotch', 'Smoky','Choclolate','Coffee','Honey','Leather','Cinnamon'
  ];

  const handleSliderChange = (setValue) => (event, newValue) => {
    setValue(newValue);
  };

  const handleFlavorClick = (flavor) => {
    setSelectedFlavors(prevFlavors =>
      prevFlavors.includes(flavor) ? prevFlavors.filter(f => f !== flavor) : [...prevFlavors, flavor]
    );
  };

  const handleConfirmConsume = async () => {
    const tastingData = {
      openedAt: new Date().toISOString(),
      acidity,
      sweetness,
      tannins,
      body,
      flavorIntensity,
      aromaIntensity,
      selectedFlavors,
      overallRating,
      tastingNotes
    };
    
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-wine-data`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  // Send token for authentication
        },
        body: JSON.stringify({
          id: wineId,
          wineData: {
            status: 'consumed', // Update status if applicable
            tasting: {
              ...tastingData
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update wine data');
      }

      const updatedWine = await response.json();
      console.log('Wine updated successfully:', updatedWine);

      // Show Snackbar on successful submission
      setSnackbarMessage('Tasting notes submitted successfully!');
      setSnackbarOpen(true);  // Open Snackbar
      setModalClosed(true); // Set modal closed state
    } catch (error) {
      console.error('Error updating wine data:', error);
      setSnackbarMessage('Error submitting tasting notes. Please try again.');
      setSnackbarOpen(true);  // Open Snackbar on error
      setModalClosed(false); // Reset modal closed state
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    if (modalClosed) {
      onClose(); // Close modal after Snackbar if it was a success
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>🥂 Dive into a Bottle of {wine.name}!</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" gutterBottom>
          Record your tasting experience and rate the flavors you enjoyed!
        </Typography>

        {/* Flavors Selection (Chips) */}
        <Box mt={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Which fruit flavors stood out to you?
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {flavorFruitOptions.map((flavor) => (
              <Chip
                key={flavor}
                label={flavor}
                clickable
                onClick={() => handleFlavorClick(flavor)}
                color={selectedFlavors.includes(flavor) ? 'primary' : 'default'}
              />
            ))}
          </Box>
        </Box>

        {/* Flavors Selection (Chips) */}
        <Box mt={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Did you notice any floral or herbal notes, or any spciy flavors?
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {flavorFloralHerbalOptions.map((flavor) => (
              <Chip
                key={flavor}
                label={flavor}
                clickable
                onClick={() => handleFlavorClick(flavor)}
                color={selectedFlavors.includes(flavor) ? 'primary' : 'default'}
              />
            ))}
          </Box>
        </Box>

        {/* Flavors Selection (Chips) */}
        <Box mt={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
          What earthy or mineral characteristics did you detect?
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {flavorEarthyMineralOptions.map((flavor) => (
              <Chip
                key={flavor}
                label={flavor}
                clickable
                onClick={() => handleFlavorClick(flavor)}
                color={selectedFlavors.includes(flavor) ? 'primary' : 'default'}
              />
            ))}
          </Box>
        </Box>

        {/* Flavors Selection (Chips) */}
        <Box mt={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
          How did oak or wood influences affect your tasting?
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {flavorOakWoodlOptions.map((flavor) => (
              <Chip
                key={flavor}
                label={flavor}
                clickable
                onClick={() => handleFlavorClick(flavor)}
                color={selectedFlavors.includes(flavor) ? 'primary' : 'default'}
              />
            ))}
          </Box>
        </Box>

        {/* Flavors Selection (Chips) */}
        <Box mt={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold' }}>
          What other unique flavors did you experience  ?
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {flavorOtherOptions.map((flavor) => (
              <Chip
                key={flavor}
                label={flavor}
                clickable
                onClick={() => handleFlavorClick(flavor)}
                color={selectedFlavors.includes(flavor) ? 'primary' : 'default'}
              />
            ))}
          </Box>
        </Box>

{/* Acidity Slider */}
<Box mt={3}>
          <Typography variant="body2" gutterBottom>Acidity (Low to High)</Typography>
          <Slider
            value={acidity}
            onChange={handleSliderChange(setAcidity)}
            step={1}
            marks={marks}
            min={1}
            max={5}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* Sweetness Slider */}
        <Box mt={2}>
          <Typography variant="body2" gutterBottom>Sweetness (Low to High)</Typography>
          <Slider
            value={sweetness}
            onChange={handleSliderChange(setSweetness)}
            step={1}
            marks={marks}
            min={1}
            max={5}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* Tannins Slider */}
        <Box mt={2}>
          <Typography variant="body2" gutterBottom>Tannins (Low to High)</Typography>
          <Slider
            value={tannins}
            onChange={handleSliderChange(setTannins)}
            step={1}
            marks={marks}
            min={1}
            max={5}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* Body Slider */}
        <Box mt={2}>
          <Typography variant="body2" gutterBottom>Body (Light to Full)</Typography>
          <Slider
            value={body}
            onChange={handleSliderChange(setBody)}
            step={1}
            marks={marks}
            min={1}
            max={5}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* Flavor Intensity Slider */}
        <Box mt={2}>
          <Typography variant="body2" gutterBottom>Flavor Intensity (Low to High)</Typography>
          <Slider
            value={flavorIntensity}
            onChange={handleSliderChange(setFlavorIntensity)}
            step={1}
            marks={marks}
            min={1}
            max={5}
            valueLabelDisplay="auto"
          />
        </Box>

        <Typography variant="body2" gutterBottom sx={{ fontWeight: 'bold', mt:2 }}>
            Did you like this wine?
          </Typography>

        {/* Flavor Intensity Slider */}
        <Box mt={2}>
          <Typography variant="body2" gutterBottom>Overall Rating (Not impressed to Great!)</Typography>
          <Slider
            value={overallRating}
            onChange={handleSliderChange(setOverallRating)}
            step={1}
            marks={marks}
            min={1}
            max={5}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* Tasting Notes */}
        <Box mt={3}>
          <TextField
            label="Tasting Notes (Optional)"
            multiline
            rows={4}
            fullWidth
            value={tastingNotes}
            onChange={(e) => setTastingNotes(e.target.value)}
            variant="outlined"
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">Cancel</Button>
        <Button onClick={handleConfirmConsume} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>

      {/* Snackbar for feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default WineTastingModal;
